<template>
  <div class="elv-project-settings-subscription-content">
    <div class="elv-project-settings-subscription-plan-info">
      <p class="elv-project-settings-subscription-title">{{ t('title.currentPlan') }}</p>
      <p class="elv-project-settings-subscription-plan">{{ currentPlan }}</p>
      <p class="elv-project-settings-subscription-status">
        <span v-if="currentStatus.label" :class="['tag', `tag-${currentStatus.status}`]">{{
          currentStatus.label
        }}</span>
        <span v-if="currentProjectData?.subscribeDate">{{
          t('common.since', { time: dayjs.utc(currentProjectData?.subscribeDate).format('YYYY/MM/DD') })
        }}</span>
        <span v-if="currentProjectData?.nextBillDate"
          >,
          {{
            t('common.nextPayment', { time: dayjs.utc(currentProjectData?.nextBillDate).format('YYYY/MM/DD') })
          }}</span
        >
        <span
          v-if="currentProjectData?.subscribeStatus === ProjectSubscribeStatus.PAUSED && currentProjectData?.pausedAt"
          >, {{ t('project.canceledOn', { time: dayjs.utc(currentProjectData?.pausedAt).format('YYYY/MM/DD') }) }}</span
        >
        <span v-if="projectSubscribeStatus.label" class="underline" @click="onClickUnSubscribe">
          , {{ projectSubscribeStatus.label }}</span
        >
      </p>
    </div>
    <div class="elv-project-settings-subscription-plan-usage">
      <p class="elv-project-settings-subscription-plan-usage-title">{{ t('common.usage') }}</p>
      <p class="elv-project-settings-subscription-plan-usage-item">
        {{ t('project.transactions') }}：
        <span class="elv-project-settings-subscription-plan-usage-value"
          >{{ formatNumber(currentProjectData?.useTxCount) }}
        </span>
        /{{ formatNumber(currentProjectData?.txCount) }}
      </p>
      <el-progress
        class="elv-project-settings-subscription-plan-usage-progress"
        :stroke-width="8"
        :show-text="false"
        :percentage="currentUsagePercent"
        :color="usageMoreThanTotal ? '#EB3A30' : '#1753EB'"
      />
      <p v-if="currentUsageTip" class="elv-project-settings-subscription-plan-usage-tip">{{ currentUsageTip }}</p>
    </div>
    <div v-if="isStripePlanProject" class="elv-project-settings-subscription-plan-history">
      <p class="elv-project-settings-subscription-plan-history-title">{{ t('title.billingHistory') }}</p>
      <p class="elv-project-settings-subscription-plan-history-tip">
        {{ t('message.billingHistoryMonths') }}
      </p>
      <CustomTable
        ref="tableListRef"
        v-loading="tableLoading"
        border
        stripe
        :tableData="billingList"
        :tableHeader="tableHeader"
        :paginationData="pageData"
        :hideSinglePage="true"
        class="elv-subscriptions-manager-table"
      >
        <template #invoiceNo="{ data }">
          <TextCell :text="data.invoiceNo || '-'" :weight="400" size="13px" fontFamily="Barlow" />
        </template>
        <template #status="{ data }">
          <span
            :class="[
              'elv-transactions-table-row__status',
              `elv-transactions-table-row__status-${data.status ? 'Paid' : 'Failed'}`
            ]"
            >{{ data?.status ? t('common.paid') : t('common.failed') }}</span
          >
        </template>
        <template #amount="{ data }">
          <TextCell
            :text="amountText(data).text || '-'"
            :titleDesc="amountText(data).desc"
            :weight="400"
            size="13px"
            fontFamily="Barlow"
            align="right"
          />
        </template>
        <template #date="{ data }">
          <TextCell
            :text="dayjs(data.date).format('YYYY/MM/DD HH:mm') || '-'"
            :weight="400"
            size="13px"
            fontFamily="Barlow"
          />
        </template>
        <template #detailTag="{ data }">
          <div class="elv-subscriptions-manager-table-view-detail" @click="handleDetail(data)">
            {{ t('project.view') }}
            <SvgIcon name="right" width="14" height="14" />
          </div>
        </template>
      </CustomTable>
    </div>
  </div>
  <ElvMessageBox
    ref="unSubscriptionMessageBoxRef"
    class="elv-apply-project-un-subscription-dialog"
    :confirmButtonText="t('common.unsubscribe')"
    :cancelButtonText="t('button.cancel')"
    :title="t('title.confirmUnSubscription')"
    :loading="confirmLoading"
    @onCancelEvent="changeUnSubscriptionDialogStatus"
    @onConfirmEvent="onClickConfirmUnSubscription"
  >
    <template #content>
      <div class="elv-apply-project-un-subscription-dialog-content">
        <i18n-t
          keypath="message.unSubscriptionTip"
          tag="p"
          class="elv-project-un-subscription-message-content"
          scope="global"
        >
          <template #time>
            <span>{{ dateAfterSevenDays }} UTC+00:00</span>
          </template>
        </i18n-t>
      </div>
    </template>
  </ElvMessageBox>
  <ResubscriptionDialog ref="reSubscriptionMessageBoxRef" />
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import utc from 'dayjs/plugin/utc'
import { isNumber } from 'lodash-es'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import { useGlobalStore } from '@/stores/modules/global'
import { useProjectStore } from '@/stores/modules/project'
import TextCell from '@/components/Base/Table/TextCell.vue'
import ResubscriptionDialog from './ResubscriptionDialog.vue'
import CustomTable from '../../Project/components/CustomTable.vue'
import { formatNumber, formatNumberToSignificantDigits } from '@/lib/utils'
import { ProjectPermissionLevel, ProjectSubscribeStatus, SubscribeInvoiceItemType } from '#/ProjectTypes'

dayjs.extend(utc)
const { t } = useI18n()
const globalStore = useGlobalStore()
const projectStore = useProjectStore()

const tableListRef = ref()
const billingList = ref<SubscribeInvoiceItemType[]>([])
const tableLoading = ref(false)
const confirmLoading = ref(false)
const unSubscriptionMessageBoxRef = useTemplateRef('unSubscriptionMessageBoxRef')
const reSubscriptionMessageBoxRef = useTemplateRef('reSubscriptionMessageBoxRef')

const pageData = ref({
  page: 1,
  limit: 20,
  sortField: 'datetime',
  sortDirection: 'DESC'
})

const tableHeader: any[] = [
  {
    dataIndex: 'invoiceNo',
    title: t('common.invoiceNo'),
    slotName: 'invoiceNo',
    width: 220
  },
  {
    dataIndex: 'status',
    title: t('common.status'),
    slotName: 'status',
    width: 100
  },
  {
    dataIndex: 'amount',
    title: t('common.amount'),
    slotName: 'amount',
    headerAlign: 'right',
    width: 100
  },
  {
    dataIndex: 'date',
    title: t('common.date'),
    slotName: 'date'
  },
  {
    dataIndex: 'detailTag',
    title: '',
    slotName: 'detailTag',
    fixed: 'right',
    width: 100
  }
]

const currentProjectData = computed(() => {
  return projectStore.projectDetail
})

const amountText = computed(() => {
  return (data: any) => {
    if (!isNumber(data.amount)) return { text: '-', desc: '' }
    const fcValue = formatNumberToSignificantDigits(data?.amount, 2, data?.unit || '')
    const descText = `${data?.unit || ''}${formatNumber(data?.amount, 20) || ''} `
    return {
      text: fcValue || '-',
      desc: descText || '-'
    }
  }
})

const currentUsagePercent = computed(() => {
  if (!currentProjectData.value?.txCount) return 0
  const useTxCount = currentProjectData.value?.useTxCount || 0
  const txCount = currentProjectData.value?.txCount || 1
  const percentage = useTxCount / txCount > 1 ? 1 : useTxCount / txCount
  return percentage * 100
})

const currentPlan = computed(() => {
  switch (currentProjectData.value?.plan) {
    case ProjectPermissionLevel.FREE:
      return t('project.basicEdition')
    case ProjectPermissionLevel.BASIC:
      return t('project.basicEdition')
    case ProjectPermissionLevel.OLD_BASIC:
      return t('project.basicEdition')
    case ProjectPermissionLevel.STANDARD:
      return t('project.standardEdition')
    default:
      return t('project.customizedEdition')
  }
})

const currentStatus = computed(() => {
  const statusInfo = { label: '', status: '' }
  switch (currentProjectData.value?.subscribeStatus) {
    case ProjectSubscribeStatus.SUBSCRIBED:
      statusInfo.label = t('common.active')
      statusInfo.status = 'Active'
      return statusInfo
    case ProjectSubscribeStatus.PAUSED:
      statusInfo.label = t('common.canceled')
      statusInfo.status = 'Canceled'
      return statusInfo
    case ProjectSubscribeStatus.PAYMENT_FAILED:
      statusInfo.label = t('common.paymentFailed')
      statusInfo.status = 'Payment-failed'
      return statusInfo
    case ProjectSubscribeStatus.EXCEEDED:
      statusInfo.label = t('common.exceededLimit')
      statusInfo.status = 'Exceeded-limit'
      return statusInfo
    default:
      return statusInfo
  }
})

const isStripePlanProject = computed(() => {
  return (
    currentProjectData.value?.plan === ProjectPermissionLevel.BASIC ||
    currentProjectData.value?.plan === ProjectPermissionLevel.STANDARD
  )
})

const usageMoreThanTotal = computed(() => {
  const useTextCount = currentProjectData.value?.useTxCount || 0
  const totalTextCount = currentProjectData.value?.txCount || 0
  return useTextCount > totalTextCount
})

const currentUsageTip = computed(() => {
  if (currentProjectData.value?.subscribeStatus === ProjectSubscribeStatus.EXCEEDED || usageMoreThanTotal.value) {
    return t('message.exceededUsageLimitTip')
  }
  if (currentProjectData.value?.plan === ProjectPermissionLevel.STANDARD) {
    return t('message.exceededTransactionLimitTip')
  }
  return ''
})

const projectSubscribeStatus = computed(() => {
  const status = { label: '', isResubscribe: false }
  if (!isStripePlanProject.value) return status
  if (currentProjectData.value) {
    if (currentProjectData.value.subscribeStatus === ProjectSubscribeStatus.PAUSED) {
      status.label = t('common.resubscribe')
      status.isResubscribe = true
      return status
    }
    if (
      (currentProjectData.value.subscribeStatus === ProjectSubscribeStatus.SUBSCRIBED ||
        currentProjectData.value.subscribeStatus === ProjectSubscribeStatus.EXCEEDED ||
        currentProjectData.value.subscribeStatus === ProjectSubscribeStatus.PAYMENT_FAILED) &&
      currentProjectData.value?.nextBillDate
    ) {
      status.label = t('common.unsubscribe')
      status.isResubscribe = false
      return status
    }
  }
  return status
})

const dateAfterSevenDays = computed(() => {
  return dayjs().tz('UTC').add(8, 'day').format('YYYY/MM/DD')
})

const changeUnSubscriptionDialogStatus = () => {
  unSubscriptionMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 *
 * @param data 查看账单详情
 */
const handleDetail = (data: any) => {
  window.open(data.detailUrl, '_blank')
}

/**
 * 更新项目信息
 */
const refreshProjectInfo = async () => {
  try {
    await globalStore.fetchProjectList()
    await projectStore.fetchProjectDetail(currentProjectData.value?.projectId || '')
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

/**
 * 点击取消订阅按钮/重新订阅按钮
 */
const onClickUnSubscribe = async () => {
  if (projectSubscribeStatus.value.isResubscribe) {
    reSubscriptionMessageBoxRef.value?.changeReSubscriptionDialogStatus()
  } else {
    changeUnSubscriptionDialogStatus()
  }
}

/**
 * 确认取消订阅
 */
const onClickConfirmUnSubscription = async () => {
  confirmLoading.value = true
  try {
    await ProjectApi.pauseSubscriptionProject(currentProjectData.value?.projectId || '')
    await refreshProjectInfo()
    unSubscriptionMessageBoxRef.value?.onCheckMessageBoxDialog()
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    confirmLoading.value = false
  }
}

/**
 * 获取账单列表
 */
const fetchBillingList = async () => {
  try {
    tableLoading.value = true
    const { data } = await ProjectApi.getSubscribeInvoices(currentProjectData.value?.projectId || '')
    billingList.value = data
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    tableLoading.value = false
  }
}

onMounted(() => {
  if (isStripePlanProject.value) {
    fetchBillingList()
  }
})
</script>

<style lang="scss" scoped>
.elv-project-settings-subscription-content {
  width: 740px;
  padding-top: 32px;
  padding-right: 30px;
  font-family: 'Plus Jakarta Sans';

  .elv-project-settings-subscription-plan-info {
    .elv-project-settings-subscription-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: #aaafb6;
      margin-bottom: 8px;
    }

    .elv-project-settings-subscription-plan {
      font-size: 28px;
      font-weight: 700;
      line-height: 35px;
      color: #0e0f11;
    }

    .elv-project-settings-subscription-status {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #636b75;
      display: flex;
      align-items: center;

      .underline {
        text-decoration: underline;
        cursor: pointer;
      }

      .tag {
        display: flex;
        height: 17px;
        padding: 0px 6px;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        margin-right: 8px;
        border-radius: 2px;
        align-items: center;
        justify-content: center;

        &-Active {
          border: 0.5px solid #1753eb;
          color: #1753eb;
        }

        &-Canceled {
          border: 0.5px solid #838d95;
          color: #838d95;
        }

        &-Payment-failed {
          border: 0.5px solid #97591b;
          color: #97591b;
        }

        &-Exceeded-limit {
          border: 0.5px solid #97591b;
          color: #97591b;
        }
      }
    }
  }

  .elv-project-settings-subscription-plan-usage {
    width: 100%;
    padding: 16px;
    border: 1px solid #dde1e6;
    border-radius: 5px;
    margin-top: 24px;
    box-sizing: border-box;

    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: #1e2024;
      margin-bottom: 8px;
    }

    &-item {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #838d95;

      .elv-project-settings-subscription-plan-usage-value {
        font-weight: 600;
        color: #1e2024;
      }
    }

    &-progress {
      margin-top: 8px;
    }

    &-tip {
      margin-top: 8px;
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
      color: #707685;
    }
  }

  .elv-project-settings-subscription-plan-history {
    margin-top: 24px;
    padding-bottom: 24px;

    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: #1e2024;
      margin-bottom: 8px;
    }

    &-tip {
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
      color: #636b75;
      margin-bottom: 8px;
    }
  }
}
</style>
<style lang="scss">
.elv-subscriptions-manager-table {
  .elv-transactions-table-row__status {
    padding: 4px 8px;
    width: fit-content;
    line-height: 13px;
    display: block;
    border-radius: 3px;

    &-Paid {
      border: 1px solid #83ae00;
      color: #83ae00;
      background-color: rgba($color: #83ae00, $alpha: 0.1);
    }

    &-Failed {
      border: 1px solid #b06921;
      color: #b06921;
      background-color: rgba($color: #b06921, $alpha: 0.1);
    }
  }

  .elv-subscriptions-manager-table-view-detail {
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-weight: 400;
    color: #1753eb;
    justify-content: flex-end;
    cursor: pointer;

    svg {
      margin-left: 4px;
    }
  }

  .elv-component-custom-table-row__cell:last-child {
    border-right: 1px solid #edf0f3;
  }

  .el-table__empty-block {
    border-right: 1px solid #edf0f3;
  }
}

.elv-apply-project-un-subscription-dialog {
  .el-dialog__body {
    padding: 23px 24px;

    .elv-apply-project-un-subscription-dialog-content {
      .elv-project-un-subscription-message-content {
        font-weight: 400;
        font-family: 'Plus Jakarta Sans';
        color: #1e2024;
        font-size: 14px;
        text-align: center;
        white-space: pre-line;

        span {
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
