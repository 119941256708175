<template>
  <el-dialog
    v-model="showEntityDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-project-entity-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-project-entity-dialog-header__title">
        {{ props.model === 'add' ? t('button.addEntity') : t('button.editEntity') }}
      </h4>
    </template>

    <EntitySettingForm
      v-if="showEntityDialog"
      ref="entityFormRef"
      v-loading="props.loading"
      :model="props.model"
      :current-entity-data="currentData"
      from-type="entity"
      @onCheckEntityDialog="onCheckEntityDialog"
    />
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import EntitySettingForm from '@/components/Project/EntitySettingForm.vue'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  loading: {
    type: Boolean,
    default: false
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()

const entityFormRef = ref()
const showEntityDialog = ref(false)

// 切换弹窗展示
const onCheckEntityDialog = () => {
  showEntityDialog.value = !showEntityDialog.value
}

defineExpose({ onCheckEntityDialog })
</script>

<style lang="scss">
.elv-project-entity-dialog {
  width: 620px;
  min-height: 565px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-project-entity-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 44px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .elv-apply-project-form {
      margin-top: 0px;
      padding-bottom: 0px;
    }

    .el-input {
      width: 572px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-transform: capitalize;
        color: #636b75;
      }
    }

    .el-select {
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-source-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }

    .elv-project-entity-dialog-footer__cancel {
      background: #edf0f3;
      color: #636b75;
    }
  }
}

.elv-source-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }

    .elv-source-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
