<template>
  <el-scrollbar
    height="352"
    view-class="elv-project-permission-view"
    class="elv-project-permission-container"
    wrap-class="elv-project-permission-wrap"
  >
    <div class="elv-project-permission-info-list">
      <div v-for="(item, index) in projectStore?.projectEntityList" :key="index">
        <div class="elv-project-permission-item__info">
          <img v-if="item.logo" :src="item.logo" :alt="item.name" :onerror="useDefaultImage" />
          <img v-else src="@/assets/img/reports/default-entity-logo.png" alt="entity" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>

    <div class="elv-project-permission-list">
      <div v-for="(item, i) in projectStore?.projectEntityList" :key="i" class="elv-project-permission-item">
        <!-- <div class="elv-project-permission-item__info">
          <img :src="item.logo" alt="" />
          <span>{{ item.name }}</span>
        </div> -->
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].dashboardCheckAll"
            :label="t('menus.dashboard')"
            :indeterminate="permissionCheckStatus[i].dashboardIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('dashboard', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox v-model="permissionData[i].dashboard.view" @change="onCheckedPermissionChange('dashboard', i)">{{
            t('common.viewListPermission', { operating: t('menus.dashboard') })
          }}</el-checkbox>
          <el-checkbox
            v-model="permissionData[i].dashboard.update"
            @change="onCheckedPermissionChange('dashboard', i)"
            >{{ t('common.editPermission', { operating: t('menus.dashboard') }) }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].entityAccountCheckAll"
            :label="t('common.account')"
            :indeterminate="permissionCheckStatus[i].entityAccountIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('entityAccount', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].entityAccount.view"
            @change="onCheckedPermissionChange('entityAccount', i)"
            >{{ t('common.viewListPermission', { operating: t('common.account') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].entityAccount.create"
            @change="onCheckedPermissionChange('entityAccount', i)"
            >{{ t('common.addNewPermission', { operating: t('common.account') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].entityAccount.update"
            @change="onCheckedPermissionChange('entityAccount', i)"
            >{{ t('common.editPermission', { operating: t('common.account') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].entityAccount.delete"
            @change="onCheckedPermissionChange('entityAccount', i)"
            >{{ t('common.deletePermission', { operating: t('common.account') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].entityAccount.balanceAdd"
            @change="onCheckedPermissionChange('entityAccount', i)"
            >{{ t('button.addBalance') }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].entityAccount.balanceVerify"
            @change="onCheckedPermissionChange('entityAccount', i)"
            >{{ t('report.verifyBalance') }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].entityAccount.balanceExport"
            @change="onCheckedPermissionChange('entityAccount', i)"
            >{{ t('report.balanceExport') }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].entityAccount.balanceDelete"
            @change="onCheckedPermissionChange('entityAccount', i)"
            >{{ t('report.balanceDelete') }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].dataSourceCheckAll"
            :label="t('report.source')"
            :indeterminate="permissionCheckStatus[i].dataSourceIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('dataSource', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].dataSource.view"
            @change="onCheckedPermissionChange('dataSource', i)"
            >{{ t('common.viewListPermission', { operating: t('report.source') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].dataSource.create"
            @change="onCheckedPermissionChange('dataSource', i)"
            >{{ t('common.addNewPermission', { operating: t('report.source') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].dataSource.update"
            @change="onCheckedPermissionChange('dataSource', i)"
            >{{ t('common.editPermission', { operating: t('report.source') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].dataSource.delete"
            @change="onCheckedPermissionChange('dataSource', i)"
            >{{ t('common.deletePermission', { operating: t('report.source') }) }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].counterpartyCheckAll"
            :label="t('common.counterparty')"
            :indeterminate="permissionCheckStatus[i].counterpartyIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('counterparty', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].counterparty.view"
            @change="onCheckedPermissionChange('counterparty', i)"
            >{{ t('common.viewListPermission', { operating: t('common.counterparty') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].counterparty.create"
            @change="onCheckedPermissionChange('counterparty', i)"
            >{{ t('common.addNewPermission', { operating: t('common.counterparty') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].counterparty.update"
            @change="onCheckedPermissionChange('counterparty', i)"
            >{{ t('common.editPermission', { operating: t('common.counterparty') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].counterparty.delete"
            @change="onCheckedPermissionChange('counterparty', i)"
            >{{ t('common.deletePermission', { operating: t('common.counterparty') }) }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].reportCheckAll"
            :label="t('common.report')"
            :indeterminate="permissionCheckStatus[i].reportIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('report', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox v-model="permissionData[i].report.view" @change="onCheckedPermissionChange('report', i)">{{
            t('common.viewListPermission', { operating: t('common.report') })
          }}</el-checkbox>
          <el-checkbox v-model="permissionData[i].report.generate" @change="onCheckedPermissionChange('report', i)">{{
            t('common.generatePermission', { operating: t('common.report') })
          }}</el-checkbox>
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].transactionCheckAll"
            :label="t('report.transaction')"
            :indeterminate="permissionCheckStatus[i].transactionIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('transaction', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].transaction.viewList"
            @change="onCheckedPermissionChange('transaction', i)"
            >{{ t('common.viewListPermission', { operating: t('report.transaction') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].transaction.viewDetail"
            @change="onCheckedPermissionChange('transaction', i)"
            >{{ t('common.viewDetailPermission', { operating: t('report.transaction') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].transaction.update"
            @change="onCheckedPermissionChange('transaction', i)"
            >{{ t('common.editPermission', { operating: t('report.transaction') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].transaction.export"
            @change="onCheckedPermissionChange('transaction', i)"
            >{{ t('common.exportPermission', { operating: t('report.transaction') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].transaction.categorize"
            @change="onCheckedPermissionChange('transaction', i)"
            >{{ t('common.categorizePermission', { operating: t('report.transaction') }) }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].journalCheckAll"
            :label="t('report.Journal')"
            :indeterminate="permissionCheckStatus[i].journalIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('journal', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox v-model="permissionData[i].journal.viewList" @change="onCheckedPermissionChange('journal', i)">{{
            t('common.viewListPermission', { operating: t('report.Journal') })
          }}</el-checkbox>
          <el-checkbox v-model="permissionData[i].journal.create" @change="onCheckedPermissionChange('journal', i)">{{
            t('common.addNewPermission', { operating: t('report.Journal') })
          }}</el-checkbox>
          <el-checkbox v-model="permissionData[i].journal.update" @change="onCheckedPermissionChange('journal', i)">{{
            t('common.editPermission', { operating: t('report.transaction') })
          }}</el-checkbox>
          <el-checkbox v-model="permissionData[i].journal.delete" @change="onCheckedPermissionChange('journal', i)">{{
            t('common.deletePermission', { operating: t('report.Journal') })
          }}</el-checkbox>
          <el-checkbox
            v-model="permissionData[i].journal.viewDetail"
            @change="onCheckedPermissionChange('journal', i)"
            >{{ t('common.viewDetailPermission', { operating: t('report.Journal') }) }}</el-checkbox
          >
          <el-checkbox v-model="permissionData[i].journal.posting" @change="onCheckedPermissionChange('journal', i)">{{
            t('button.recalculateCostBasis')
          }}</el-checkbox>
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].businessDataCheckAll"
            :label="t('title.businessDataIntegration')"
            :indeterminate="permissionCheckStatus[i].businessDataIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('businessData', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].businessData.view"
            @change="onCheckedPermissionChange('businessData', i)"
            >{{ t('common.viewListPermission', { operating: t('title.businessDataIntegration') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].businessData.update"
            @change="onCheckedPermissionChange('businessData', i)"
            >{{ t('common.editPermission', { operating: t('title.businessDataIntegration') }) }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].generalLedgerCheckAll"
            :label="t('project.nav.generalLedger')"
            :indeterminate="permissionCheckStatus[i].generalLedgerIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('generalLedger', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].generalLedger.view"
            @change="onCheckedPermissionChange('generalLedger', i)"
            >{{ t('common.viewListPermission', { operating: t('project.nav.generalLedger') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].generalLedger.sync"
            @change="onCheckedPermissionChange('generalLedger', i)"
            >{{ t('common.syncToErp', { operating: t('project.nav.generalLedger') }) }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].automationRuleCheckAll"
            :label="t('common.automation')"
            :indeterminate="permissionCheckStatus[i].automationRuleIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('automationRule', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox
            v-model="permissionData[i].automationRule.view"
            @change="onCheckedPermissionChange('automationRule', i)"
            >{{ t('common.viewListPermission', { operating: t('common.automation') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].automationRule.create"
            @change="onCheckedPermissionChange('automationRule', i)"
            >{{ t('common.addNewPermission', { operating: t('common.automation') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].automationRule.update"
            @change="onCheckedPermissionChange('automationRule', i)"
            >{{ t('common.editPermission', { operating: t('common.automation') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].automationRule.delete"
            @change="onCheckedPermissionChange('automationRule', i)"
            >{{ t('common.deletePermission', { operating: t('common.automation') }) }}</el-checkbox
          >
          <el-checkbox
            v-model="permissionData[i].automationRule.execute"
            @change="onCheckedPermissionChange('automationRule', i)"
            >{{ t('common.executePermission', { operating: t('common.automation') }) }}</el-checkbox
          >
        </div>
        <div class="elv-project-permission-level-1">
          <el-checkbox
            v-model="permissionCheckStatus[i].valuationCheckAll"
            :label="t('common.valuation')"
            :indeterminate="permissionCheckStatus[i].valuationIsIndeterminate"
            @change="onCheckPermissionClassifyAllChange('valuation', i, $event)"
          />
        </div>
        <div class="elv-project-permission-level-2">
          <el-checkbox v-model="permissionData[i].valuation.view" @change="onCheckedPermissionChange('valuation', i)">
            {{ t('common.viewRecords') }}
          </el-checkbox>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { isEmpty, throttle } from 'lodash-es'
import { useProjectStore } from '@/stores/modules/project'
import defaultEntityLogo from '@/assets/img/reports/default-entity-logo.png'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  currentPermissions: {
    type: Array,
    default: () => {
      return []
    }
  }
})

const { t } = useI18n()
const projectStore = useProjectStore()

const permissionCheckStatus: any = ref([])
const permissionData: any = ref([])

const useDefaultImage = (event: any) => {
  event.target.src = defaultEntityLogo
}

const onCheckPermissionClassifyAllChange = (type: string, index: number, val: any) => {
  if (val) {
    permissionCheckStatus.value[index][`${type}CheckAll`] = true
    Object.keys(permissionData.value[index][type])
    permissionData.value[index][type] = Object.keys(permissionData.value[index][type]).reduce((obj: any, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = true
      return obj
    }, {})
  } else {
    permissionCheckStatus.value[index][`${type}CheckAll`] = false
    permissionData.value[index][type] = Object.keys(permissionData.value[index][type]).reduce((obj: any, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = false
      return obj
    }, {})
  }
  permissionCheckStatus.value[index][`${type}IsIndeterminate`] = false
}

const onCheckedPermissionChange = (type: string, index: number) => {
  permissionCheckStatus.value[index][`${type}CheckAll`] = Object.values(permissionData.value[index][type]).every(
    (val) => val === true
  )
  permissionCheckStatus.value[index][`${type}IsIndeterminate`] =
    Object.values(permissionData.value[index][type]).some((val) => val === true) &&
    !permissionCheckStatus.value[index][`${type}CheckAll`]
  permissionCheckStatus.value[index].checkAll = Object.values(permissionData.value[index]).every((obj: any) => {
    return Object.values(obj).every((val) => val === true)
  })
}

const rightScroll = () => {
  const top = document.getElementsByClassName('elv-project-permission-wrap')[0].scrollTop
  document.getElementsByClassName('elv-project-member-permission-wrap')[0].scrollTop = top
}

defineExpose({ permissionData })

watch(
  () => props.currentPermissions,
  () => {
    if (props.model === 'add') {
      permissionCheckStatus.value = projectStore?.projectEntityList.map(() => {
        return {
          dashboardCheckAll: false,
          entityAccountCheckAll: false,
          dataSourceCheckAll: false,
          counterpartyCheckAll: false,
          reportCheckAll: false,
          transactionCheckAll: false,
          journalCheckAll: false,
          businessDataCheckAll: false,
          generalLedgerCheckAll: false,
          automationRuleCheckAll: false,
          valuationCheckAll: false,
          dashboardIsIndeterminate: false,
          entityAccountIsIndeterminate: false,
          dataSourceIsIndeterminate: false,
          counterpartyIsIndeterminate: false,
          reportIsIndeterminate: false,
          transactionIsIndeterminate: false,
          journalIsIndeterminate: false,
          businessDataIsIndeterminate: false,
          generalLedgerIsIndeterminate: false,
          automationRuleIsIndeterminate: false,
          valuationIsIndeterminate: false
        }
      })
      permissionData.value = projectStore?.projectEntityList.map(() => {
        return {
          dashboard: {
            view: false,
            update: false
          },
          entityAccount: {
            view: false,
            create: false,
            update: false,
            delete: false,
            balanceAdd: false,
            balanceVerify: false,
            balanceExport: false,
            balanceDelete: false
          },
          dataSource: {
            view: false,
            create: false,
            update: false,
            delete: false
          },
          counterparty: {
            view: false,
            create: false,
            update: false,
            delete: false
          },
          transaction: {
            viewList: false,
            viewDetail: false,
            update: false,
            export: false,
            categorize: false
          },
          journal: {
            viewList: false,
            create: false,
            delete: false,
            update: false,
            viewDetail: false,
            posting: false
          },
          businessData: {
            view: false,
            update: false
          },
          report: {
            view: false,
            generate: false
          },
          automationRule: {
            view: false,
            create: false,
            update: false,
            delete: false,
            execute: false
          },
          generalLedger: {
            view: false,
            sync: false
          },
          valuation: {
            view: false
          }
        }
      })
    } else {
      permissionData.value = projectStore?.projectEntityList.map((i: any) => {
        const currentPermission: any = props.currentPermissions.find((current: any) => {
          return current.entityId === i.entityId
        })
        if (isEmpty(currentPermission) || !currentPermission) {
          return {
            dashboard: {
              view: false,
              update: false
            },
            entityAccount: {
              view: false,
              create: false,
              update: false,
              delete: false,
              balanceAdd: false,
              balanceVerify: false,
              balanceExport: false,
              balanceDelete: false
            },
            dataSource: {
              view: false,
              create: false,
              update: false,
              delete: false
            },
            counterparty: {
              view: false,
              create: false,
              update: false,
              delete: false
            },
            transaction: {
              viewList: false,
              viewDetail: false,
              update: false,
              export: false,
              categorize: false
            },
            journal: {
              viewList: false,
              viewDetail: false,
              create: false,
              delete: false,
              update: false,
              posting: false
            },
            businessData: {
              view: false,
              update: false
            },
            report: {
              view: false,
              generate: false
            },
            automationRule: {
              view: false,
              create: false,
              update: false,
              delete: false,
              execute: false
            },
            generalLedger: {
              view: false,
              sync: false
            },
            valuation: {
              view: false
            }
          }
        }
        return currentPermission?.permission
      })

      permissionCheckStatus.value = projectStore?.projectEntityList.map((i: any) => {
        const currentPermission: any = props.currentPermissions.find((current: any) => {
          return current.entityId === i.entityId
        })
        if (isEmpty(currentPermission) || !currentPermission) {
          return {
            dashboardCheckAll: false,
            entityAccountCheckAll: false,
            dataSourceCheckAll: false,
            counterpartyCheckAll: false,
            reportCheckAll: false,
            transactionCheckAll: false,
            journalCheckAll: false,
            generalLedgerCheckAll: false,
            automationRuleCheckAll: false,
            valuationCheckAll: false,
            dashboardIsIndeterminate: false,
            entityAccountIsIndeterminate: false,
            dataSourceIsIndeterminate: false,
            counterpartyIsIndeterminate: false,
            reportIsIndeterminate: false,
            transactionIsIndeterminate: false,
            journalIsIndeterminate: false,
            generalLedgerIsIndeterminate: false,
            automationRuleIsIndeterminate: false,
            valuationIsIndeterminate: false
          }
        }
        const checkStatus: any = {}
        Object.keys(currentPermission?.permission).forEach((item: any) => {
          checkStatus[`${item}CheckAll`] = Object.values(currentPermission?.permission[item]).every(
            (val) => val === true
          )
          checkStatus[`${item}IsIndeterminate`] =
            Object.values(currentPermission?.permission[item]).some((val) => val === true) &&
            !checkStatus[`${item}CheckAll`]
        })
        return checkStatus
      })
    }
  },
  { immediate: true }
)

onBeforeMount(() => {})

onMounted(() => {
  nextTick(() => {
    document.getElementsByClassName('elv-project-permission-wrap')[0]?.addEventListener('scroll', throttle(rightScroll))
  })
})

onBeforeUnmount(() => {
  document.getElementsByClassName('elv-project-permission-wrap')[0]?.removeEventListener('scroll', rightScroll)
})
</script>

<style lang="scss" scoped>
.elv-project-permission-container {
  position: absolute;
  top: 0px;
  right: 0;
  // padding-top: 26px;
}

:deep(.elv-project-permission-wrap) {
  width: 300px;
}

:deep(.elv-project-permission-view) {
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;

  .elv-project-permission-info-list {
    display: flex;
    justify-content: flex-end;
    height: 18px;
    margin-bottom: 8px;

    > div {
      .elv-project-permission-item__info {
        width: 100px;
        display: flex;
        align-items: stretch;
        justify-content: flex-end;

        img {
          width: 16px;
          height: 16px;
          display: block;
          margin-right: 4px;
        }

        span {
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-weight: 400;
          line-height: 14px;
          color: #000000;
          word-break: break-word;
        }
      }
    }
  }

  .elv-project-permission-list {
    display: flex;
    justify-content: flex-end;
  }

  .elv-project-permission-item {
    width: 100px;

    .elv-project-permission-level-1,
    .elv-project-permission-level-2 {
      width: 100px;
      border: 0px;
      background-color: transparent;

      .el-checkbox {
        width: 100%;
        border: 0px;
      }
    }
  }

  .el-checkbox__label {
    display: none !important;
  }
}
</style>
