<template>
  <el-dialog
    v-model="showMemberDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-project-setting-member-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-project-setting-member-dialog-header__title">
        {{ props.model === 'add' ? t('button.addMember') : t('button.editMember') }}
      </h4>
    </template>
    <el-form ref="memberFormRef" :model="memberForm" :rules="rules" label-position="top" @submit.prevent>
      <el-form-item label="Member email" prop="email" class="elv-project-form-base-info">
        <el-input v-model.trim="memberForm.email" :disabled="props.model === 'edit'" placeholder="" />
        <el-checkbox
          v-model="memberForm.role"
          label="Set as Administrator"
          true-value="ADMIN"
          false-value="MEMBER"
          @change="onChangeRole"
        />
      </el-form-item>
      <el-form-item label="Permission" class="elv-project-form-permission">
        <div class="elv-project-permission">
          <div
            v-if="projectStore?.projectEntityList.length > 1 && memberForm.role === 'MEMBER'"
            class="elv-project-permission-same"
          >
            <el-checkbox v-model="sameInAllEntities" :label="t('common.sameInAllEntities')" />
          </div>
          <el-scrollbar
            height="326"
            view-class="elv-project-member-permission-content"
            wrap-class="elv-project-member-permission-wrap"
          >
            <div :class="{ 'is-disabled': !sameInAllEntities }">
              <div class="elv-project-permission-level-1">
                <el-checkbox
                  v-model="permissionCheckStatus.dashboardCheckAll"
                  :label="t('menus.dashboard')"
                  :disabled="memberForm.role === 'ADMIN'"
                  :indeterminate="permissionCheckStatus.dashboardIsIndeterminate"
                  @change="onCheckPermissionClassifyAllChange('dashboard', $event)"
                />
              </div>
              <div class="elv-project-permission-level-2">
                <el-checkbox
                  v-model="permissionData.dashboard.view"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('dashboard')"
                  >{{ t('common.viewListPermission', { operating: t('menus.dashboard') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.dashboard.update"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('dashboard')"
                  >{{ t('common.editPermission', { operating: t('menus.dashboard') }) }}</el-checkbox
                >
              </div>
              <div class="elv-project-permission-level-1">
                <el-checkbox
                  v-model="permissionCheckStatus.entityAccountCheckAll"
                  :label="t('common.account')"
                  :disabled="memberForm.role === 'ADMIN'"
                  :indeterminate="permissionCheckStatus.entityAccountIsIndeterminate"
                  @change="onCheckPermissionClassifyAllChange('entityAccount', $event)"
                />
              </div>
              <div class="elv-project-permission-level-2">
                <el-checkbox
                  v-model="permissionData.entityAccount.view"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('entityAccount')"
                  >{{ t('common.viewListPermission', { operating: t('common.account') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.entityAccount.create"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('entityAccount')"
                  >{{ t('common.addNewPermission', { operating: t('common.account') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.entityAccount.update"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('entityAccount')"
                  >{{ t('common.editPermission', { operating: t('common.account') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.entityAccount.delete"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('entityAccount')"
                  >{{ t('common.deletePermission', { operating: t('common.account') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.entityAccount.balanceAdd"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('entityAccount')"
                  >{{ t('button.addBalance') }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.entityAccount.balanceVerify"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('entityAccount')"
                  >{{ t('report.verifyBalance') }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.entityAccount.balanceExport"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('entityAccount')"
                  >{{ t('report.balanceExport') }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.entityAccount.balanceDelete"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('entityAccount')"
                  >{{ t('report.balanceDelete') }}</el-checkbox
                >
              </div>
              <div class="elv-project-permission-level-1">
                <el-checkbox
                  v-model="permissionCheckStatus.dataSourceCheckAll"
                  :label="t('report.source')"
                  :disabled="memberForm.role === 'ADMIN'"
                  :indeterminate="permissionCheckStatus.dataSourceIsIndeterminate"
                  @change="onCheckPermissionClassifyAllChange('dataSource', $event)"
                />
              </div>
              <div class="elv-project-permission-level-2">
                <el-checkbox
                  v-model="permissionData.dataSource.view"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('dataSource')"
                  >{{ t('common.viewListPermission', { operating: t('report.source') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.dataSource.create"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('dataSource')"
                  >{{ t('common.addNewPermission', { operating: t('report.source') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.dataSource.update"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('dataSource')"
                  >{{ t('common.editPermission', { operating: t('report.source') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.dataSource.delete"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('dataSource')"
                  >{{ t('common.deletePermission', { operating: t('report.source') }) }}</el-checkbox
                >
              </div>
              <div class="elv-project-permission-level-1">
                <el-checkbox
                  v-model="permissionCheckStatus.counterpartyCheckAll"
                  :label="t('common.counterparty')"
                  :disabled="memberForm.role === 'ADMIN'"
                  :indeterminate="permissionCheckStatus.counterpartyIsIndeterminate"
                  @change="onCheckPermissionClassifyAllChange('counterparty', $event)"
                />
              </div>
              <div class="elv-project-permission-level-2">
                <el-checkbox
                  v-model="permissionData.counterparty.view"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('counterparty')"
                  >{{ t('common.viewListPermission', { operating: t('common.counterparty') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.counterparty.create"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('counterparty')"
                  >{{ t('common.addNewPermission', { operating: t('common.counterparty') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.counterparty.update"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('counterparty')"
                  >{{ t('common.editPermission', { operating: t('common.counterparty') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.counterparty.delete"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('counterparty')"
                  >{{ t('common.deletePermission', { operating: t('common.counterparty') }) }}</el-checkbox
                >
              </div>
              <div class="elv-project-permission-level-1">
                <el-checkbox
                  v-model="permissionCheckStatus.reportCheckAll"
                  :label="t('common.report')"
                  :disabled="memberForm.role === 'ADMIN'"
                  :indeterminate="permissionCheckStatus.reportIsIndeterminate"
                  @change="onCheckPermissionClassifyAllChange('report', $event)"
                />
              </div>
              <div class="elv-project-permission-level-2">
                <el-checkbox
                  v-model="permissionData.report.view"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('report')"
                  >{{ t('common.viewListPermission', { operating: t('common.report') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.report.generate"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('report')"
                  >{{ t('common.generatePermission', { operating: t('common.report') }) }}</el-checkbox
                >
              </div>
              <div class="elv-project-permission-level-1">
                <el-checkbox
                  v-model="permissionCheckStatus.transactionCheckAll"
                  :label="t('report.transaction')"
                  :disabled="memberForm.role === 'ADMIN'"
                  :indeterminate="permissionCheckStatus.transactionIsIndeterminate"
                  @change="onCheckPermissionClassifyAllChange('transaction', $event)"
                />
              </div>
              <div class="elv-project-permission-level-2">
                <el-checkbox
                  v-model="permissionData.transaction.viewList"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('transaction')"
                  >{{ t('common.viewListPermission', { operating: t('report.transaction') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.transaction.viewDetail"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('transaction')"
                  >{{ t('common.viewDetailPermission', { operating: t('report.transaction') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.transaction.update"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('transaction')"
                  >{{ t('common.editPermission', { operating: t('report.transaction') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.transaction.export"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('transaction')"
                  >{{ t('common.exportPermission', { operating: t('report.transaction') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.transaction.categorize"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('transaction')"
                  >{{ t('common.categorizePermission', { operating: t('report.transaction') }) }}</el-checkbox
                >
              </div>
              <div class="elv-project-permission-level-1">
                <el-checkbox
                  v-model="permissionCheckStatus.journalCheckAll"
                  :label="t('report.Journal')"
                  :disabled="memberForm.role === 'ADMIN'"
                  :indeterminate="permissionCheckStatus.journalIsIndeterminate"
                  @change="onCheckPermissionClassifyAllChange('journal', $event)"
                />
              </div>
              <div class="elv-project-permission-level-2">
                <el-checkbox
                  v-model="permissionData.journal.viewList"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('journal')"
                  >{{ t('common.viewListPermission', { operating: t('report.Journal') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.journal.create"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('journal')"
                  >{{ t('common.addNewPermission', { operating: t('report.Journal') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.journal.update"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('journal')"
                  >{{ t('common.editPermission', { operating: t('report.Journal') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.journal.delete"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('journal')"
                  >{{ t('common.deletePermission', { operating: t('report.Journal') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.journal.viewDetail"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('journal')"
                  >{{ t('common.viewDetailPermission', { operating: t('report.Journal') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.journal.posting"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('journal')"
                  >{{ t('button.recalculateCostBasis') }}</el-checkbox
                >
              </div>
              <div class="elv-project-permission-level-1">
                <el-checkbox
                  v-model="permissionCheckStatus.businessDataCheckAll"
                  :label="t('title.businessDataIntegration')"
                  :disabled="memberForm.role === 'ADMIN'"
                  :indeterminate="permissionCheckStatus.businessDataIsIndeterminate"
                  @change="onCheckPermissionClassifyAllChange('businessData', $event)"
                />
              </div>
              <div class="elv-project-permission-level-2">
                <el-checkbox
                  v-model="permissionData.businessData.view"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('businessData')"
                  >{{ t('common.viewListPermission', { operating: t('title.businessDataIntegration') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.businessData.update"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('businessData')"
                  >{{ t('common.editPermission', { operating: t('title.businessDataIntegration') }) }}</el-checkbox
                >
              </div>
              <div class="elv-project-permission-level-1">
                <el-checkbox
                  v-model="permissionCheckStatus.generalLedgerCheckAll"
                  :label="t('project.nav.generalLedger')"
                  :disabled="memberForm.role === 'ADMIN'"
                  :indeterminate="permissionCheckStatus.generalLedgerIsIndeterminate"
                  @change="onCheckPermissionClassifyAllChange('generalLedger', $event)"
                />
              </div>
              <div class="elv-project-permission-level-2">
                <el-checkbox
                  v-model="permissionData.generalLedger.view"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('generalLedger')"
                  >{{ t('common.viewListPermission', { operating: t('project.nav.generalLedger') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.generalLedger.sync"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('generalLedger')"
                  >{{ t('common.syncToErp', { operating: t('project.nav.generalLedger') }) }}</el-checkbox
                >
              </div>
              <div class="elv-project-permission-level-1">
                <el-checkbox
                  v-model="permissionCheckStatus.automationRuleCheckAll"
                  :label="t('common.automation')"
                  :disabled="memberForm.role === 'ADMIN'"
                  :indeterminate="permissionCheckStatus.automationRuleIsIndeterminate"
                  @change="onCheckPermissionClassifyAllChange('automationRule', $event)"
                />
              </div>
              <div class="elv-project-permission-level-2">
                <el-checkbox
                  v-model="permissionData.automationRule.view"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('automationRule')"
                  >{{ t('common.viewListPermission', { operating: t('common.automation') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.automationRule.create"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('automationRule')"
                  >{{ t('common.addNewPermission', { operating: t('common.automation') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.automationRule.update"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('automationRule')"
                  >{{ t('common.editPermission', { operating: t('common.automation') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.automationRule.delete"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('automationRule')"
                  >{{ t('common.deletePermission', { operating: t('common.automation') }) }}</el-checkbox
                >
                <el-checkbox
                  v-model="permissionData.automationRule.execute"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('automationRule')"
                  >{{ t('common.executePermission', { operating: t('common.automation') }) }}</el-checkbox
                >
              </div>
              <div class="elv-project-permission-level-1">
                <el-checkbox
                  v-model="permissionCheckStatus.valuationCheckAll"
                  :label="t('common.valuation')"
                  :disabled="memberForm.role === 'ADMIN'"
                  :indeterminate="permissionCheckStatus.valuationIsIndeterminate"
                  @change="onCheckPermissionClassifyAllChange('valuation', $event)"
                />
              </div>
              <div class="elv-project-permission-level-2">
                <el-checkbox
                  v-model="permissionData.valuation.view"
                  :disabled="memberForm.role === 'ADMIN'"
                  @change="onCheckedPermissionChange('valuation')"
                  >{{ t('common.viewRecords') }}</el-checkbox
                >
              </div>
            </div>
          </el-scrollbar>
          <Permission
            v-if="!sameInAllEntities"
            ref="permissionListRef"
            :model="props.model"
            :role="memberForm.role"
            :current-permissions="currentMemberPermissionData"
          />
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <elv-button
        width="95"
        height="44"
        round
        type="primary"
        :loading="submitLoading"
        :disabled="memberForm.email === ''"
        @click="onClickConnect"
        >{{ props.model === 'add' ? t('button.add') : t('button.save') }}</elv-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import Permission from './Permission.vue'
import { useProjectStore } from '@/stores/modules/project'
import type { FormInstance, FormRules } from 'element-plus'
import { forEach, every, isEmpty, partial, isEqual, throttle, upperFirst } from 'lodash-es'
// import { useReportStore } from '@/stores/modules/reports/index.js'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
// const reportStore = useReportStore()
const projectStore = useProjectStore()

const memberForm = reactive({
  email: '',
  role: 'MEMBER'
})

const route = useRoute()
const submitLoading = ref(false)
const showMemberDialog = ref(false)
const sameInAllEntities = ref(false)
const memberFormRef = ref<FormInstance>()
const permissionListRef = ref()

const rules = reactive<FormRules>({
  email: {
    required: true,
    trigger: 'blur',
    message: 'Member Email is required'
  }
})

const permissionCheckStatus: any = ref({
  dashboardCheckAll: false,
  entityAccountCheckAll: false,
  dataSourceCheckAll: false,
  counterpartyCheckAll: false,
  reportCheckAll: false,
  transactionCheckAll: false,
  journalCheckAll: false,
  businessDataCheckAll: false,
  generalLedgerCheckAll: false,
  automationRuleCheckAll: false,
  valuationCheckAll: false,
  dashboardIsIndeterminate: false,
  entityAccountIsIndeterminate: false,
  dataSourceIsIndeterminate: false,
  counterpartyIsIndeterminate: false,
  reportIsIndeterminate: false,
  transactionIsIndeterminate: false,
  journalIsIndeterminate: false,
  businessDataIsIndeterminate: false,
  generalLedgerIsIndeterminate: false,
  automationRuleIsIndeterminate: false,
  valuationIsIndeterminate: false
})

const permissionData: any = ref({
  dashboard: {
    view: false,
    update: false
  },
  entityAccount: {
    view: false,
    create: false,
    update: false,
    delete: false,
    balanceAdd: false,
    balanceVerify: false,
    balanceExport: false,
    balanceDelete: false
  },
  dataSource: {
    view: false,
    create: false,
    update: false,
    delete: false
  },
  counterparty: {
    view: false,
    create: false,
    update: false,
    delete: false
  },
  transaction: {
    viewList: false,
    viewDetail: false,
    update: false,
    export: false,
    categorize: false
  },
  journal: {
    viewList: false,
    create: false,
    update: false,
    delete: false,
    viewDetail: false,
    posting: false
  },
  businessData: {
    view: false,
    update: false
  },
  report: {
    view: false,
    generate: false
  },
  automationRule: {
    view: false,
    create: false,
    update: false,
    delete: false,
    execute: false
  },
  generalLedger: {
    view: false,
    sync: false
  },
  valuation: {
    view: false
  }
})

const projectId = computed(() => {
  return String(route.params?.projectId)
})

const currentMemberPermissionData = computed(() => {
  return props.currentData?.entityList?.map((item: any) => {
    return { entityId: item.entityId, permission: projectStore.entityPermission(item) }
  })
})

// 切换删除弹窗展示
const onCheckMemberDialog = () => {
  showMemberDialog.value = !showMemberDialog.value
}

const onCloseDialog = () => {
  memberForm.email = ''
  memberFormRef.value?.resetFields()
}

const onCheckPermissionClassifyAllChange = (type: string, val: any) => {
  if (val) {
    permissionCheckStatus.value[`${type}CheckAll`] = true
    Object.keys(permissionData.value[type])
    permissionData.value[type] = Object.keys(permissionData.value[type]).reduce((obj: any, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = true
      return obj
    }, {})
  } else {
    permissionCheckStatus.value[`${type}CheckAll`] = false
    permissionData.value[type] = Object.keys(permissionData.value[type]).reduce((obj: any, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = false
      return obj
    }, {})
  }
  permissionCheckStatus.value[`${type}IsIndeterminate`] = false
}

const onCheckedPermissionChange = (type: string) => {
  permissionCheckStatus.value[`${type}CheckAll`] = Object.values(permissionData.value[type]).every(
    (val) => val === true
  )
  permissionCheckStatus.value[`${type}IsIndeterminate`] =
    Object.values(permissionData.value[type]).some((val) => val === true) &&
    !permissionCheckStatus.value[`${type}CheckAll`]
  permissionCheckStatus.value.checkAll = Object.values(permissionData.value).every((obj: any) => {
    return Object.values(obj).every((val) => val === true)
  })
}

const onChangeRole = () => {
  if (memberForm.role === 'ADMIN') {
    sameInAllEntities.value = true
    permissionData.value = {
      dashboard: {
        view: true,
        update: true
      },
      entityAccount: {
        view: true,
        create: true,
        update: true,
        delete: true,
        balanceAdd: true,
        balanceVerify: true,
        balanceExport: true,
        balanceDelete: true
      },
      dataSource: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      counterparty: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      transaction: {
        viewList: true,
        viewDetail: true,
        update: true,
        export: true,
        categorize: true
      },
      journal: {
        viewList: true,
        create: true,
        update: true,
        delete: true,
        viewDetail: true,
        posting: true
      },
      businessData: {
        view: true,
        update: true
      },
      report: {
        view: true,
        generate: true
      },
      automationRule: {
        view: true,
        create: true,
        update: true,
        delete: true,
        execute: true
      },
      generalLedger: {
        view: true,
        sync: true
      },
      valuation: {
        view: true
      }
    }
    permissionCheckStatus.value = {
      dashboardCheckAll: true,
      entityAccountCheckAll: true,
      dataSourceCheckAll: true,
      counterpartyCheckAll: true,
      reportCheckAll: true,
      transactionCheckAll: true,
      journalCheckAll: true,
      businessDataCheckAll: true,
      generalLedgerCheckAll: true,
      automationRuleCheckAll: true,
      valuationCheckAll: true,
      dashboardIsIndeterminate: false,
      entityAccountIsIndeterminate: false,
      dataSourceIsIndeterminate: false,
      counterpartyIsIndeterminate: false,
      reportIsIndeterminate: false,
      transactionIsIndeterminate: false,
      journalIsIndeterminate: false,
      businessDataIsIndeterminate: false,
      generalLedgerIsIndeterminate: false,
      automationRuleIsIndeterminate: false,
      valuationIsIndeterminate: false
    }
  }
}

const onClickConnect = async () => {
  if (!memberFormRef.value) return
  await memberFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        submitLoading.value = true
        const formattedPermissions: any = {}
        forEach(permissionData.value, (value: any, key: any) => {
          forEach(value, (v: any, k: any) => {
            const formattedKey = `${key}${upperFirst(k)}`
            formattedPermissions[formattedKey] = v
          })
        })
        const params: any = {
          email: memberForm.email,
          role: memberForm.role
        }
        if (sameInAllEntities.value) {
          params.permissions = projectStore.projectEntityList.map((entity: any) => {
            return {
              entityId: entity.entityId,
              permission: formattedPermissions
            }
          })
        } else {
          params.permissions = projectStore.projectEntityList.map((item: any, i: number) => {
            const formattedPermission: any = {}
            forEach(permissionListRef.value?.permissionData[i], (value: any, key: any) => {
              forEach(value, (v: any, k: any) => {
                const formattedKey = `${key}${upperFirst(k)}`
                formattedPermission[formattedKey] = v
              })
            })
            return {
              permission: formattedPermission,
              entityId: item.entityId
            }
          })
        }
        if (props.model === 'edit') {
          delete params.email
          await ProjectApi.editProjectMember(projectId.value, props.currentData?.userId, params)
        } else {
          await ProjectApi.addProjectMember(projectId.value, params)
          projectStore.fetchProjectDetail(projectId.value)
        }
        submitLoading.value = false
        memberFormRef.value?.resetFields()
        projectStore.fetchProjectMemberList(projectId.value)
        permissionData.value = {
          dashboard: {
            view: true,
            update: true
          },
          entityAccount: {
            view: true,
            create: true,
            update: true,
            delete: true,
            balanceAdd: true,
            balanceVerify: true,
            balanceExport: true,
            balanceDelete: true
          },
          dataSource: {
            view: true,
            create: true,
            update: true,
            delete: true
          },
          counterparty: {
            view: true,
            create: true,
            update: true,
            delete: true
          },
          transaction: {
            viewList: true,
            viewDetail: true,
            update: true,
            export: true,
            categorize: true
          },
          journal: {
            viewList: true,
            create: true,
            update: true,
            delete: true,
            viewDetail: true,
            posting: true
          },
          businessData: {
            view: true,
            update: true
          },
          report: {
            view: true,
            generate: true
          },
          automationRule: {
            view: true,
            create: true,
            update: true,
            delete: true,
            execute: true
          },
          generalLedger: {
            view: true,
            sync: true
          },
          valuation: {
            view: true
          }
        }
        permissionCheckStatus.value = {
          dashboardCheckAll: true,
          entityAccountCheckAll: true,
          dataSourceCheckAll: true,
          counterpartyCheckAll: true,
          reportCheckAll: true,
          transactionCheckAll: true,
          journalCheckAll: true,
          businessDataCheckAll: true,
          generalLedgerCheckAll: true,
          automationRuleCheckAll: true,
          valuationCheckAll: true,
          dashboardIsIndeterminate: false,
          entityAccountIsIndeterminate: false,
          dataSourceIsIndeterminate: false,
          counterpartyIsIndeterminate: false,
          reportIsIndeterminate: false,
          transactionIsIndeterminate: false,
          journalIsIndeterminate: false,
          businessDataIsIndeterminate: false,
          generalLedgerIsIndeterminate: false,
          automationRuleIsIndeterminate: false,
          valuationIsIndeterminate: false
        }
        showMemberDialog.value = false
        ElMessage.success(t('message.saveSuccess'))
      } catch (error: any) {
        submitLoading.value = false
        ElMessage.error(error?.message)
        console.log(error)
      }
    }
  })
}

const leftScroll = () => {
  const top = document.getElementsByClassName('elv-project-member-permission-wrap')[0]?.scrollTop
  if (!sameInAllEntities.value) {
    document.getElementsByClassName('elv-project-permission-wrap')[0].scrollTop = top
  }
}

defineExpose({ onCheckMemberDialog })

watch([() => props.currentData, () => showMemberDialog.value], () => {
  if (showMemberDialog.value) {
    nextTick(() => {
      if (!isEmpty(props.currentData) && props.model === 'edit') {
        memberForm.email = props.currentData.email
        memberForm.role = props.currentData.role || 'MEMBER'
        const permissionDataArr = currentMemberPermissionData.value.map((item: any) => item.permission)
        const isSame = every(permissionDataArr, partial(isEqual, permissionDataArr[0]))
        sameInAllEntities.value =
          isSame && currentMemberPermissionData.value.length === projectStore.projectEntityList.length
        if (sameInAllEntities.value) {
          Object.keys(currentMemberPermissionData.value[0].permission).forEach((item) => {
            permissionCheckStatus.value[`${item}CheckAll`] = Object.values(
              currentMemberPermissionData.value[0].permission[item]
            ).every((val) => val === true)
            permissionCheckStatus.value[`${item}IsIndeterminate`] =
              Object.values(currentMemberPermissionData.value[0].permission[item]).some((val) => val === true) &&
              !permissionCheckStatus.value[`${item}CheckAll`]
          })
          // eslint-disable-next-line prefer-destructuring
          permissionData.value = currentMemberPermissionData.value[0].permission
        }
      } else if (isEmpty(props.currentData) && props.model === 'add') {
        memberForm.email = ''
        sameInAllEntities.value = true
        memberFormRef.value?.resetFields()
        permissionData.value = {
          dashboard: {
            view: true,
            update: true
          },
          entityAccount: {
            view: true,
            create: true,
            update: true,
            delete: true,
            balanceAdd: true,
            balanceVerify: true,
            balanceExport: true,
            balanceDelete: true
          },
          dataSource: {
            view: true,
            create: true,
            update: true,
            delete: true
          },
          counterparty: {
            view: true,
            create: true,
            update: true,
            delete: true
          },
          transaction: {
            viewList: true,
            viewDetail: true,
            update: true,
            export: true,
            categorize: true
          },
          journal: {
            viewList: true,
            create: true,
            update: true,
            delete: true,
            viewDetail: true,
            posting: true
          },
          businessData: {
            view: true,
            update: true
          },
          report: {
            view: true,
            generate: true
          },
          automationRule: {
            view: true,
            create: true,
            update: true,
            delete: true,
            execute: true
          },
          generalLedger: {
            view: true,
            sync: true
          },
          valuation: {
            view: true
          }
        }
        permissionCheckStatus.value = {
          dashboardCheckAll: true,
          entityAccountCheckAll: true,
          dataSourceCheckAll: true,
          counterpartyCheckAll: true,
          reportCheckAll: true,
          transactionCheckAll: true,
          journalCheckAll: true,
          businessDataCheckAll: true,
          generalLedgerCheckAll: true,
          automationRuleCheckAll: true,
          valuationCheckAll: true,
          dashboardIsIndeterminate: false,
          entityAccountIsIndeterminate: false,
          dataSourceIsIndeterminate: false,
          counterpartyIsIndeterminate: false,
          reportIsIndeterminate: false,
          transactionIsIndeterminate: false,
          journalIsIndeterminate: false,
          businessDataIsIndeterminate: false,
          generalLedgerIsIndeterminate: false,
          automationRuleIsIndeterminate: false,
          valuationIsIndeterminate: false
        }
      }
    })
  }
})

watchEffect(() => {})

// onMounted(() => {})

watch(
  () => showMemberDialog.value,
  () => {
    if (showMemberDialog.value) {
      nextTick(() => {
        document
          .getElementsByClassName('elv-project-member-permission-wrap')[0]
          ?.addEventListener('scroll', throttle(leftScroll))
      })
    } else {
      document
        .getElementsByClassName('elv-project-member-permission-wrap')[0]
        ?.removeEventListener('scroll', leftScroll)
    }
  }
)

onBeforeUnmount(() => {
  document.getElementsByClassName('elv-project-member-permission-wrap')[0]?.removeEventListener('scroll', leftScroll)
})
</script>

<style lang="scss">
.elv-project-setting-member-dialog {
  width: 620px;
  min-height: 565px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-project-setting-member-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 44px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .el-input {
      width: 572px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-transform: capitalize;
        color: #636b75;
      }

      &.elv-project-form-permission {
        .el-form-item__label {
          color: #0e0f11;
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 16px;
        }
      }

      &.elv-project-form-base-info {
        .el-checkbox {
          height: 18px;
          margin-top: 8px;

          .el-checkbox__label {
            padding-left: 4px;
            color: #000;
            font-family: 'Plus Jakarta Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    .el-select {
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-source-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }

    .elv-project-setting-member-dialog-footer__cancel {
      background: #edf0f3;
      color: #636b75;
    }

    .elv-project-setting-member-dialog-footer__delete {
      margin-left: 20px;
    }
  }
}

.elv-project-permission {
  width: 100%;

  .el-scrollbar__view.elv-project-member-permission-content {
    width: 572px !important;
    position: relative;

    .is-disabled {
      .el-checkbox .el-checkbox__inner {
        display: none;
      }
    }
  }

  .elv-project-permission-same {
    .el-checkbox {
      height: 18px;
      padding: 0px;
      margin-bottom: 8px;
      flex-direction: row;
      justify-content: flex-start;

      .el-checkbox__input {
        padding-left: 2px;
      }

      .el-checkbox__label {
        color: #000;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .el-checkbox {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #1e2024;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 8px;
    height: 34px;

    &.is-checked {
      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #1e2024;
      }
    }

    .el-checkbox__input.is-checked:not(.is-disabled) .el-checkbox__inner {
      border-color: #1753eb;
      background-color: #1753eb;

      &:after {
        left: 3.5px;
      }
    }

    .el-checkbox__input.is-checked.is-disabled .el-checkbox__inner {
      border-color: #90abf0;
      background-color: #90abf0;

      &:after {
        border-color: #f9fafb;
      }
    }

    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      border-color: #1753eb;
      background-color: #fff;

      &::before {
        height: 2.5px;
        top: 4.5px;
        background-color: #1753eb;
      }
    }

    .el-checkbox__inner {
      width: 13.5px;
      height: 13.5px;
      box-sizing: border-box;

      border: 1.5px solid #d0d4d9;
      background: #fff;
    }

    .el-checkbox__input {
      width: 18px;
      height: 18px;
      display: flex;
      justify-items: center;
      align-items: center;
    }

    .el-checkbox__label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #0e0f11;

      img {
        width: 18px;
        height: 18px;
        display: block;
      }

      span {
        font-weight: 500;
        margin-left: 8px;
      }
    }
  }

  .elv-project-permission-level-1 {
    background: #f9fafb;
    border-bottom: 1px solid #edf0f3;

    &:first-child {
      border-top: 1px solid #edf0f3;
    }

    .el-checkbox__label {
      font-weight: 600;
    }
  }

  .elv-project-permission-level-2 {
    .el-checkbox {
      border-bottom: 1px solid #edf0f3;
    }

    .el-checkbox__label {
      margin-left: 40px;
    }
  }
}

.elv-source-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }

    .elv-source-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        width: 32px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
