<template>
  <div v-loading="loading" class="elv-entity-setting-integrations">
    <div class="elv-entity-setting-integration-screening">
      <template v-if="projectStore.projectEntityList.length > 1">
        <el-select v-model="entityId" placeholder=" ">
          <el-option
            v-for="item in projectStore.projectEntityList"
            :key="item.entityId"
            :label="item.name"
            :value="item.entityId"
          />
        </el-select>
      </template>
      <div
        v-for="(item, index) in options"
        :key="index"
        :class="{ 'is-selected': optionValue === item.value }"
        class="elv-entity-setting-integration-screening__item"
        @click="onChangeNav(item.value)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="elv-entity-setting-integration-list">
      <div
        v-if="optionValue === 'ALL' || optionValue === 'BOOKKEEPING'"
        class="elv-entity-setting-integration-item elv-entity-setting-integrations__quickbooks"
      >
        <div>
          <img src="@/assets/img/reports/Logo-Quickbooks.png" alt="Quickbooks" />
          <div class="elv-entity-setting-integrations-operating">
            <elv-button v-if="true" height="32" plain
              ><SvgIcon name="link" width="16" height="16" />{{ t('button.connect') }}</elv-button
            >
            <template v-else>
              <elv-button height="32" plain
                ><SvgIcon name="unlink" width="16" height="16" />{{ t('button.disconnect') }}</elv-button
              >
              <elv-button height="32" type="primary">{{ t('button.accountMappings') }}</elv-button>
            </template>
          </div>
        </div>
        <p class="elv-entity-setting-integrations-info">
          {{ t('title.connectIntegrationInfo', { platform: 'Quickbooks' }) }}
        </p>
      </div>
      <div
        v-if="optionValue === 'ALL' || optionValue === 'BOOKKEEPING'"
        class="elv-entity-setting-integrations__xero elv-entity-setting-integration-item"
      >
        <div>
          <img src="@/assets/img/reports/Logo-Xero.png" alt="Xero" />
          <div class="elv-entity-setting-integrations-operating">
            <elv-button v-if="false" height="32" plain @click="onConnectERP"
              ><SvgIcon name="link" width="16" height="16" />{{ t('button.connect') }}</elv-button
            >
            <!-- <elv-button v-if="!entityStore.entityDetail?.hasIntegration" height="32" plain @click="onConnectERP"
              ><SvgIcon name="link" width="16" height="16" />{{ t('button.connect') }}</elv-button
            > -->
            <template v-else>
              <elv-button height="32" plain @click="onDisconnectIntegration"
                ><SvgIcon name="unlink" width="16" height="16" />{{ t('button.disconnect') }}</elv-button
              >
              <elv-button height="32" type="primary" :loading="accountMappingLoading" @click="onEditAccountMappings">{{
                t('button.accountMappings')
              }}</elv-button>
            </template>
          </div>
        </div>
        <p class="elv-entity-setting-integrations-info">
          {{ t('title.connectIntegrationInfo', { platform: 'Xero' }) }}
        </p>
      </div>
      <div
        v-if="optionValue === 'ALL' || optionValue === 'CUSTODIAN'"
        class="elv-entity-setting-integration-item elv-entity-setting-integrations__cobo"
      >
        <div>
          <img src="@/assets/img/reports/Logo-Cobo.png" alt="Cobo" />
          <div class="elv-entity-setting-integrations-operating">
            <elv-button v-if="true" height="32" plain
              ><SvgIcon name="link" width="16" height="16" />{{ t('button.connect') }}</elv-button
            >
            <template v-else>
              <elv-button height="32" plain
                ><SvgIcon name="unlink" width="16" height="16" />{{ t('button.disconnect') }}</elv-button
              >
              <elv-button height="32" type="primary">{{ t('button.accountMappings') }}</elv-button>
            </template>
          </div>
        </div>
        <p class="elv-entity-setting-integrations-info">
          {{ t('title.connectIntegrationWalletInfo', { platform: 'Cobo' }) }}
        </p>
      </div>
      <div
        v-if="optionValue === 'ALL' || optionValue === 'CUSTODIAN'"
        class="elv-entity-setting-integration-item elv-entity-setting-integrations__sinohope"
      >
        <div>
          <img src="@/assets/img/reports/Logo-Sinohope.png" alt="Sinohope" />
          <div class="elv-entity-setting-integrations-operating">
            <elv-button v-if="true" height="32" plain
              ><SvgIcon name="link" width="16" height="16" />{{ t('button.connect') }}</elv-button
            >
            <template v-else>
              <elv-button height="32" plain
                ><SvgIcon name="unlink" width="16" height="16" />{{ t('button.disconnect') }}</elv-button
              >
              <elv-button height="32" type="primary">{{ t('button.accountMappings') }}</elv-button>
            </template>
          </div>
        </div>
        <p class="elv-entity-setting-integrations-info">
          {{ t('title.connectIntegrationWalletInfo', { platform: 'Sinohope' }) }}
        </p>
      </div>
      <div
        v-if="optionValue === 'ALL' || optionValue === 'WALLETS'"
        class="elv-entity-setting-integration-item elv-entity-setting-integrations__fireblocks"
      >
        <div>
          <img src="@/assets/img/reports/Logo-Fireblocks.png" alt="Fireblocks" />
          <div class="elv-entity-setting-integrations-operating">
            <elv-button v-if="true" height="32" plain
              ><SvgIcon name="link" width="16" height="16" />{{ t('button.connect') }}</elv-button
            >
            <template v-else>
              <elv-button height="32" plain
                ><SvgIcon name="unlink" width="16" height="16" />{{ t('button.disconnect') }}</elv-button
              >
              <elv-button height="32" type="primary">{{ t('button.accountMappings') }}</elv-button>
            </template>
          </div>
        </div>
        <p class="elv-entity-setting-integrations-info">
          {{ t('title.connectIntegrationWalletInfo', { platform: 'Fireblocks' }) }}
        </p>
      </div>
      <div
        v-if="optionValue === 'ALL' || optionValue === 'WALLETS'"
        class="elv-entity-setting-integration-item elv-entity-setting-integrations__metamask"
      >
        <div>
          <img src="@/assets/img/reports/Logo-Metamask.png" alt="Metamask" />
          <div class="elv-entity-setting-integrations-operating">
            <elv-button v-if="true" height="32" plain
              ><SvgIcon name="link" width="16" height="16" />{{ t('button.connect') }}</elv-button
            >
            <template v-else>
              <elv-button height="32" plain
                ><SvgIcon name="unlink" width="16" height="16" />{{ t('button.disconnect') }}</elv-button
              >
              <elv-button height="32" type="primary">{{ t('button.accountMappings') }}</elv-button>
            </template>
          </div>
        </div>
        <p class="elv-entity-setting-integrations-info">
          {{ t('title.connectIntegrationWalletInfo', { platform: 'Metamask' }) }}
        </p>
      </div>
      <div
        v-if="optionValue === 'ALL' || optionValue === 'CUSTODIAN'"
        class="elv-entity-setting-integration-item elv-entity-setting-integrations__stripe"
      >
        <div>
          <img src="@/assets/img/reports/Logo-Stripe.png" alt="Stripe" />
          <div class="elv-entity-setting-integrations-operating">
            <elv-button v-if="true" height="32" plain
              ><SvgIcon name="link" width="16" height="16" />{{ t('button.connect') }}</elv-button
            >
            <template v-else>
              <elv-button height="32" plain
                ><SvgIcon name="unlink" width="16" height="16" />{{ t('button.disconnect') }}</elv-button
              >
              <elv-button height="32" type="primary">{{ t('button.accountMappings') }}</elv-button>
            </template>
          </div>
        </div>
        <p class="elv-entity-setting-integrations-info">
          {{ t('title.connectIntegrationWalletInfo', { platform: 'Stripe' }) }}
        </p>
      </div>
      <div
        v-if="optionValue === 'ALL' || optionValue === 'CUSTODIAN'"
        class="elv-entity-setting-integration-item elv-entity-setting-integrations__checkout"
      >
        <div>
          <img src="@/assets/img/reports/Logo-Checkout.png" alt="Checkout" />
          <div class="elv-entity-setting-integrations-operating">
            <elv-button v-if="true" height="32" plain
              ><SvgIcon name="link" width="16" height="16" />{{ t('button.connect') }}</elv-button
            >
            <template v-else>
              <elv-button height="32" plain
                ><SvgIcon name="unlink" width="16" height="16" />{{ t('button.disconnect') }}</elv-button
              >
              <elv-button height="32" type="primary">{{ t('button.accountMappings') }}</elv-button>
            </template>
          </div>
        </div>
        <p class="elv-entity-setting-integrations-info">
          {{ t('title.connectIntegrationWalletInfo', { platform: 'Checkout' }) }}
        </p>
      </div>
      <div
        v-if="optionValue === 'ALL' || optionValue === 'OPENAPI'"
        class="elv-entity-setting-integrations__openApi elv-entity-setting-integration-item"
      >
        <div>
          <div class="elv-entity-setting-integrations-content">
            <div>
              <!-- <SvgIcon name="Integration-platform" width="32" height="32" /> -->
              <img src="@/assets/img/default-logo.png" alt="business-logo" />
              <p class="elv-entity-setting-integration-platform__title">Business Data</p>
            </div>
            <p class="elv-entity-setting-integrations-info">Business data OTC business system</p>
            <div class="elv-entity-setting-integrations-content__info">
              <div>
                <p>Settlement: 756</p>
                <p>Trade: 14,815</p>
              </div>
              <p>Last synced on 2023/11/01 12:23:01</p>
            </div>
          </div>
          <div class="elv-entity-setting-integrations-operating"></div>
        </div>
      </div>
      <div class="elv-entity-setting-integration-item add-new">
        <elv-button type="primary" plain round width="95" height="32"
          ><SvgIcon name="source-add" width="16" height="16" />{{ t('button.addNew') }}</elv-button
        >
      </div>
    </div>
  </div>
  <AccountMappingsDialog ref="accountMappingsRef" />

  <ElvMessageBox
    ref="disconnectPopConfirmRef"
    :confirm-button-text="t('button.disconnect')"
    :cancel-button-text="t('button.cancel')"
    :title="t('title.disconnectWithPlatform', { platfrom: 'Xero' })"
    :loading="disconnectLoading"
    @onConfirmEvent="onConfirmDisconnect"
    @onCancelEvent="onCloseConfirm"
  >
    <template #content>
      <span class="elv-confirm-disconnect-info__title">{{
        t('message.disconnectWithPlatformInfo', { platfrom: 'Xero' })
      }}</span></template
    >
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { isMobile } from '@/lib/utils'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import { useEntityStore } from '@/stores/modules/entity'
import { useProjectStore } from '@/stores/modules/project'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import AccountMappingsDialog from '@/components/Reports/AccountMappingsDialog.vue'

const { t } = useI18n()
const route = useRoute()

const entityStore = useEntityStore()
const projectStore = useProjectStore()
const userGlobalStore = useUserGlobalStore()
const menuGlobalStore = useMenuGlobalStore()

const disconnectPopConfirmRef = ref()
const accountMappingsRef = ref()
const loading = ref(false)
const disconnectLoading = ref(false)
const accountMappingLoading = ref(false)
const optionValue = ref('ALL')
const entityId = ref('')

const options = ref([
  {
    label: t('common.all'),
    value: 'ALL'
  },
  {
    label: t('common.wallets'),
    value: 'WALLETS'
  },
  {
    label: t('common.bookkeeping'),
    value: 'BOOKKEEPING'
  },
  {
    label: t('common.custodian'),
    value: 'CUSTODIAN'
  },
  {
    label: t('common.openApi'),
    value: 'OPENAPI'
  }
])

const projectId = computed(() => {
  return String(route.params.projectId)
})

const contentWidth = computed(() => {
  return isMobile() || !menuGlobalStore.isFixedMenu ? 'calc(100vw - 48px)' : 'calc(100vw - 179px)'
})

const onConnectERP = () => {
  const redirectUrl = encodeURIComponent(`${import.meta.env.VITE_XERO_REDIRECT_URL}`)
  const scope = encodeURIComponent(
    'offline_access openid profile email accounting.transactions accounting.settings accounting.attachments'
  )
  const clientId = import.meta.env.VITE_XERO_CLIENT_ID

  const url = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scope}&state=${userGlobalStore.user?.userId}|${entityId.value}|${projectId.value}`
  window.open(url, '_self')
}

const onChangeNav = (nav: string) => {
  optionValue.value = nav
}

const onDisconnectIntegration = async () => {
  disconnectPopConfirmRef.value?.onCheckMessageBoxDialog()
}

const onCloseConfirm = () => {
  disconnectPopConfirmRef.value?.onCheckMessageBoxDialog()
}

const onConfirmDisconnect = async () => {
  try {
    disconnectLoading.value = true
    await ReportsApi.cancelEntityAuthErp(entityId.value)
    ElMessage.success(t('message.successfullyDisconnected', { platform: 'Xero' }))
    entityStore.fetchEntityDetail(entityId.value)
    disconnectPopConfirmRef.value?.onCheckMessageBoxDialog()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error?.message)
  } finally {
    disconnectLoading.value = false
  }
}

const onEditAccountMappings = async () => {
  try {
    accountMappingLoading.value = true
    // await reportStore.fetchAccountMappingList(entityId.value)
    accountMappingsRef.value?.onCheckAccountMappingsDialog()
  } catch (error: any) {
    ElMessage.error(error?.message)
    console.log(error)
  } finally {
    accountMappingLoading.value = false
  }
}

watchEffect(async () => {
  if (route.query?.entityId) {
    entityId.value = String(route.query?.entityId)
  } else if (entityId.value === '') {
    entityId.value = projectStore.projectEntityList[0]?.entityId
  }
  try {
    loading.value = true
    await entityStore.fetchEntityDetail(entityId.value)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
})
</script>

<style lang="scss" scoped>
.elv-entity-setting-integrations {
  width: v-bind('contentWidth');
  padding-left: 20px;
  box-sizing: border-box;
  padding-top: 12px;
}

.elv-entity-setting-integration-screening {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  :deep(.el-select) {
    width: fit-content;
    margin-right: 16px;

    .el-select__wrapper {
      height: 32px;
      width: 160px;
    }
  }

  .elv-entity-setting-integration-screening__item {
    display: flex;
    padding: 0px 16px;
    height: 28px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    border: 1px solid #edf0f3;
    margin-right: 16px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:last-child {
      margin-right: 0px;
    }

    &:not(.is-selected):hover {
      border: 1px solid #5e85eb;
      color: #1343bf;
    }

    &.is-selected {
      border: 1px solid #2f63eb;
      background: #2f63eb;
      color: #fff;
    }
  }
}

.elv-entity-setting-integration-list {
  display: flex;
  width: 1160px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;

  .elv-entity-setting-integration-item {
    width: 572px;
    height: 150px;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;
    background-color: #f9fafb;

    &.add-new {
      align-items: center;
      justify-content: center;
    }

    .elv-entity-setting-integrations-operating {
      display: flex;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        display: block;
      }
    }
  }
}

.elv-entity-setting-integrations__openApi {
  width: 572px;
  height: 150px;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
  background-color: #f9fafb;
  margin-bottom: 16px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img,
    svg {
      width: 32px;
      height: 32px;
      display: block;
    }

    .elv-entity-setting-integration-platform__title {
      color: #000;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-left: 8px;
    }

    .elv-entity-setting-integrations-content {
      width: 100%;

      > div {
        display: flex;
        align-items: center;
      }

      .elv-entity-setting-integrations-content__info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        > div {
          display: flex;
          align-items: center;

          p {
            color: #1e2024;
            font-family: 'Barlow';
            font-weight: 500;
            font-size: 12px;
            font-style: normal;
            line-height: normal;
            margin-left: 8px;

            &:first-of-type {
              margin-left: 0px;
            }
          }
        }

        p {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }

    .elv-entity-setting-integrations-operating {
      display: flex;

      :deep(.elv-button.is-plain) {
        color: #1e2024;
        border-color: #dde1e6;

        svg {
          fill: #1e2024;
        }

        &.is-disabled {
          color: #d0d4d9 !important;
          background-color: #f9fafb !important;
          box-shadow: none !important;

          svg {
            fill: #d0d4d9 !important;
          }
        }

        &:not(.is-disabled):focus,
        &:not(.is-disabled):hover {
          border: 1px solid #5e85eb;
          color: #1343bf;

          svg {
            fill: #1343bf;
          }
        }
      }
    }
  }
}

.elv-entity-setting-integrations__quickbooks {
  img {
    width: 118px;
    height: 32px;
  }
}

.elv-entity-setting-integrations__cobo {
  img {
    width: 115px;
    height: 20.855px;
  }
}

.elv-entity-setting-integrations__xero {
  img {
    width: 32px;
    height: 32px;
  }
}

.elv-entity-setting-integrations__sinohope {
  img {
    width: 97.969px;
    height: 22px;
  }
}

.elv-entity-setting-integrations__fireblocks {
  img {
    width: 118px;
    height: 18px;
  }
}

.elv-entity-setting-integrations__metamask {
  img {
    width: 116px;
    height: 23.2px;
  }
}

.elv-entity-setting-integrations__stripe {
  img {
    width: 54px;
    height: 24px;
  }
}

.elv-entity-setting-integrations__checkout {
  img {
    width: 149.673px;
    height: 21px;
  }
}

.elv-confirm-disconnect-info__title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: start;
  color: #1e2024;
  word-break: break-word;
}

.elv-entity-setting-integrations-info {
  color: #636b75;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 10px;
}
</style>
